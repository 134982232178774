import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 1000px;
    max-width: 100%;
    margin: 0 auto;
    padding: 0 20px;
    //background-color: var(--light-three);
`;

export const Content = styled.div`
    width: 100%;
    padding: 0 20px;
    //margin-left: 10px;
    margin-bottom: 20px;
`;

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: ${({ theme }) => theme.mobile}) {
    flex-direction: column;
    text-align: center;
  }
`