import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 1000px;
    max-width: 100%;
    padding: 0 20px;
    margin: 0 auto;
    //background-color: var(--light-three);
    //height: 550px;
    
`

export const Content = styled.div`
    width: 100%;
    margin-left: 10px;
    padding: 0 20px;
    margin-bottom: 20px;

`
export const H2 = styled.h2`
    text-align: center !important;
    padding-bottom: 5px;
    display: inline-block;
    font-weight: 300;
    position: relative;

    &:before {
        content: "";
        position: absolute;
        width: 50%;
        height: 1px;
        bottom: 0;
        left: 25%;
        border-bottom: 1px solid #784cfb;
    }

`

export const StyledCard = styled.div`
    background-color: #363d4a; //#ebfbff;#c1e4f0#232a34 #2d343e
    border-radius: 15px;
    //border-style: solid;
    //border-color: #000;
    margin: 40px 40px;
    padding: 60px;

    h3, li {
        text-align: center;
        color: #fff;
    }
    
`

export const Separator = styled.p`
    margin-left: 10px;
    //margin-right: 2px;
`

export const SkillsFlex = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: ${({ theme }) => theme.mobile}) {
    //flex-direction: column;
    margin-left: 35px;
    align-items: center;
  }
`