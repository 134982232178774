/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import Logo from '../images/mySite_svg.svg';
import { Wrapper, 
    MyLogo,
    LeftContainer,
    RightContainer,
    NavbarInnerContainer,
    NavbarLinkContainer,
    NavbarExtendedContainer ,
    NavbarLink,
    NavbarLinkExtended,
    OpenLinksButton,
    HeaderContainer
} from "./styles/Header.styled";


const Header = () => {
    const [extendNavbar, setExtendNavbar] = useState(false);
    return (
        <Wrapper extendNav={extendNavbar} >
            <HeaderContainer className='container'>
            <NavbarInnerContainer>
                <LeftContainer>
                    <NavbarLinkContainer>
                        <NavbarLink to='/'> Home</NavbarLink>
                        <NavbarLink to='/projects'> Projects</NavbarLink>
                        <NavbarLink to='/skills'> Technical skills</NavbarLink>
                        <NavbarLink to='/contact-me'> Contact me</NavbarLink>
                        <OpenLinksButton
                            onClick={() => {
                                setExtendNavbar((curr) => !curr);
                            }}
                            > 
                            {extendNavbar ? <> &#10005; </> : <>  &#8801;</>}
                        </OpenLinksButton>
                    </NavbarLinkContainer>
                </LeftContainer>

                {/* <RightContainer>
                    <Link to='/'>
                        <MyLogo src={Logo} alt='my-logo'/>
                    </Link> 
                </RightContainer> */}
            </NavbarInnerContainer>

            <NavbarExtendedContainer>
                <NavbarLinkExtended to='/'> Home</NavbarLinkExtended>
                <NavbarLinkExtended to='/projects'> Projects</NavbarLinkExtended>
                <NavbarLinkExtended to='/skills'> My skills</NavbarLinkExtended>
                <NavbarLinkExtended to='/contact-me'> Contact me</NavbarLinkExtended>
            </NavbarExtendedContainer>
            </HeaderContainer>
        </Wrapper >
    );
};

export default Header;
