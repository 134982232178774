import styled from 'styled-components';

export const Wrapper = styled.footer`
    background-color: #232a34;
    //var(--lightBlue)  #144352;
    color: #fff;
    padding: 100px 0 60px;
    /* margin-top: 500px; */

    ul {
        list-style-type: none;
        color: white;
    }

    
    ul li {
        margin-bottom: 20px;
        text-decoration: none;
        color: white;

    }


    p {
        text-align: right;
    }

    @media screen and (max-width: ${({ theme }) => theme.mobile}) {
        text-align: center;

        ul {
            padding: 0;
        }

        p {
            text-align: center;
        }
    }
`;

export const Image = styled.img`
    max-width: 180px;
    height: 30px;
    margin: 10px;
`;