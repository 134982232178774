import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
//components
import Header from './components/Header';
import Footer from './components/Footer';
import Projects from './components/Projects';
import TechnicalSkills from './components/TechnicalSkills';
import Home from './components/Home';
import ContactMe from './components/ContactMe';
import GetResume from './components/GetResume';

// styles
import { GlobalStyle } from './components/styles/GlobalStyle';


//utils


const theme = {
  colors: {
    header: '#ebfbff',
    body: '#f6f7fb',
    footer: '#3366ff',
  },
  sizing: 'border-box',
  mobile: '760px'
}


const App = () => {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Header />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/projects' element={<Projects />} />
          <Route path='/skills' element={<TechnicalSkills />} />
          <Route path='/contact-me' element={<ContactMe />} />
          <Route path='/contact-me/resume' element={<GetResume />} />
        </Routes>
        {/* <Home /> */}
        
        <Footer />
      </ThemeProvider>
    </Router>
  );
}

export default App;
