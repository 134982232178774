import React from 'react';
import { Wrapper, Content, FlexContainer } from './styles/Projects.styled';
import content from '../utils/content';
//import Card from './Card';
import CardProject from './CardProject';
// import TestCard from './TestCard';
// import Pagination from './Pagination';

const Projects = () => {
  return (
    <Wrapper>
     {/*  { content.map((item, index) => (<Card key={index} item={item}/>)) }
      */}
      <Content>
        {/* { content.map((item, index) => (<CardProject key={index} project={item}/>)) } */}
        <FlexContainer>
          <CardProject project={content[0]} />
          <CardProject project={content[1]} />
        </FlexContainer>
        <FlexContainer>
          <CardProject project={content[2]} />
          <CardProject project={content[3]} />
        </FlexContainer>
        <FlexContainer>
          <CardProject project={content[4]} />
          {/* <CardProject project={content[5]} /> */}
        </FlexContainer>
      </Content>
    </Wrapper>
  );
};

export default Projects;
