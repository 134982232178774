import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Wrapper = styled.nav`
    

      width: 100%;
      height: ${(props) => (props.extendNav ? '100vh' : '80px')};
      background: #7588a3;//#98d3e6;
      //#784cfb #a0bcdb #237490 #1e647b #46afd2;
      padding: 0 20px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      /* box-sizing: ${({ theme }) => theme.sizing}; */
    

    @media screen and (min-width: 700px) {
      height: 80px;
    }
`;

export const HeaderContainer = styled.div``;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: var(--maxWidth);
  padding: 20px 0;
  margin: 0 auto;
  color: var(--white);

  a {
    color: var(--white);
    text-decoration: none;
  }
`;

export const LeftContainer = styled.div`
  flex: 70%;
  display: flex;
  align-items: center;
  padding-left: 5%;
`;

export const RightContainer = styled.div`
  flex: 30%;
  display: flex;
  justify-content: flex-end;
  padding-right: 50px;
`;

export const NavbarInnerContainer = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
`;

export const NavbarLinkContainer = styled.div`
  display: flex;
`;

export const MyLogo = styled.img`
    max-width: 180px;
    height: 30px;
    margin: 10px;
`;

export const NavbarLink = styled(Link)`
  

    color: #000;
    font-size: 1.2rem;
    font-family: Arial, Helvetica, sans-serif;
    text-decoration: none;
    margin: 10px;


  @media screen and (max-width: 700px) {
    display: none;
  }
`;

export const NavbarLinkExtended = styled(Link)`
  color: #000; //white
  font-size: 1.1rem;
  font-family: Arial, Helvetica, sans-serif;
  text-decoration: none;
  margin: 25px 10px 10px 10px;
`;

export const OpenLinksButton = styled.button`
  
    width: 70px;
    height: 50px;
    background: none;
    border: none;
    color: white;
    font-size: 45px;
    cursor: pointer;


  @media screen and (min-width: 700px) {
    display: none;
  }
`;

export const NavbarExtendedContainer = styled.div`
  

    display: flex;
    flex-direction: column;
    align-items: center;


  @media screen and (min-width: 700px) {
    display: none;
  }
`;