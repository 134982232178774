import React from 'react';
import { Flex } from './styles/Flex.styled'
import { 
  Wrapper, 
  Content,
  H2,
  StyledCard,
  Separator,
  SkillsFlex
} from './styles/TechnicalSkills.styled';
import { FaGit, FaGithub, FaReact, FaHtml5 } from 'react-icons/fa'
import { DiGit } from 'react-icons/di'
import { IoLogoJavascript, IoLogoCss3, 
  IoLogoNodejs,
  IoLogoNpm 
} from 'react-icons/io'
import { SiRedux, SiMongodb, SiMysql, SiStyledcomponents } from 'react-icons/si'

const TechnicalSkills = () => {
  return (
    <Wrapper>
        <Content>
          <H2>My Skills</H2>
         <Flex>
            <StyledCard>
              <h3>Frontend Development</h3>
              <ul>
                <li>JavaScript</li>
                <li>ReactJS</li>
                <li>Redux</li>
                <li>HTML</li>
                <li>CSS</li>
                <li>GitHub</li>
              </ul>
            </StyledCard>
            <StyledCard >
              <h3>Backend Development</h3>
              <ul>
                <li>MERN Stack</li>
                <li>Node.js</li>
                <li>ExpressJS</li>
                <li>MongoDB</li>
                <li>MySQL</li>
                <li>Git</li>
              </ul>
          </StyledCard>
         </Flex>
         <SkillsFlex>
           <FaGithub />
           <Separator />
           <FaGit />
           <Separator />
           <DiGit />
           <Separator />
           <FaHtml5 />
           <Separator />
           <IoLogoCss3 />
           <Separator />
           <IoLogoJavascript />
           <Separator />
           <FaReact />
           <Separator />
           <SiStyledcomponents />
           <Separator />
           <SiRedux />
         </SkillsFlex>
         <SkillsFlex>
            <Separator />
            <IoLogoNodejs />
            <Separator />
            <IoLogoNpm />
            <Separator />
            <SiMongodb />
            <Separator />
            <SiMysql />
         </SkillsFlex>
        </Content>
    </Wrapper>
  );
};

export default TechnicalSkills;
