import React from 'react';
import { Wrapper } from './styles/GetResume.styled';
import { saveAs } from 'file-saver';
import { Button } from './styles/Button.styled';
import { ImPointDown } from 'react-icons/im'

export const GetResume = () => {
  const saveFile = () => {
    saveAs(
      'https://rkmsite.s3.us-east-2.amazonaws.com/assets/resume.pdf', 
    'richard_kalibbala_resume.pdf'
    );
  }

  return (
      <Wrapper>
          <h2>My resume here..</h2>
          {/* <a href="https://rkmsite.s3.us-east-2.amazonaws.com/assets/resume.pdf" 
              target="_blank"
              rel='noreferrer'
              download>
                Download
          </a> */}
          <h3><ImPointDown /></h3>
          <Button bg={`teal`} color='#fff' onClick={saveFile}>Download Resume</Button>
      </Wrapper>
  );
};

export default GetResume;
