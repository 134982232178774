import { 
    ProjectCard, 
    Content, 
    BtnContainer, 
    Button,
    CardProjectFlex 
} from './styles/CardProject.styled';

const CardProject = ({ project }) => {

    const { title, body, gitLink, appLink} = project;


    return (
        <ProjectCard>
            <Content>
                <h2>{title}</h2>
                <p>{body}</p>
            </Content>

            <BtnContainer>
                {/* <Button bg='#405cf5' color='#fff' onClick={viewOnGithub}>View on GitHub</Button > */}
                {/* <Button bg='#405cf5' color='#fff' onClick={viewApp}>View App</Button > */}
                <CardProjectFlex>
                    <form action={gitLink} target={`_blank`}>
                        <Button bg='#405cf5' color='#fff'>View on GitHub</Button >
                    </form>
                    <form action={appLink} target={`_blank`}>
                        <Button bg='#405cf5' color='#fff'>View App</Button>
                    </form>
                </CardProjectFlex>
            </BtnContainer>
        </ProjectCard>
    )
}

export default CardProject;