import React from "react";
import SocialIcons from './SocialIcons';
import { Link } from 'react-router-dom';
import { Container } from './styles/Container.styled';
import Logo from '../images/mySite_svg.svg';
import { Flex } from './styles/Flex.styled';
import { Wrapper, Image  } from "./styles/Footer.styled";


const Footer = () => {
    return (
        <Wrapper>
            <Container>
                <Image src={Logo} alt="logo" />
        
                <h3>Richard Kalibbala</h3>

                <Flex>
                    <ul>
                        <li>
                        Web Developer &#128187; Software Engineer

                        </li>
                        
                        <li>richardmel@outlook.com</li>
                    </ul>
                    <ul>
                        <Link to='/projects' style={{ textDecoration: 'none' }}><li>Projects</li></Link>
                        <Link style={{ textDecoration: 'none' }} to='/contact-me/resume'><li>Resume</li></Link>
                        <a style={{ textDecoration: 'none' }} href="https://github.com/future3004"
                            target="_blank"
                            rel="noopener noreferrer">
                                <li>GitHub Repo</li>
                        </a>
                    </ul>

                    <ul>
                        <Link style={{ textDecoration: 'none' }} to='/'><li>Home</li></Link>
                        <Link style={{ textDecoration: 'none' }} to='/contact-me'><li>Contact Me</li></Link>
                    </ul>

                    <SocialIcons />
                </Flex>

                <p>&copy; 2022 Richard Kalibbala. All rights reserved</p>
            </Container>
        </Wrapper>
    );
};

export default Footer;