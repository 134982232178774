import { FaTwitter, FaGithub, FaLinkedin } from 'react-icons/fa'
import { StyledSocialIcons } from './styles/SocialIcons.styled'

const SocialIcons = () => {
  return (
    <StyledSocialIcons>
      <li>
        <a href="https://twitter.com/richard_km5"
            target="_blank"
            rel="noopener noreferrer">
          <FaTwitter />
        </a>
      </li>
      <li>
        <a href='https://www.linkedin.com/in/richard-kalibbala-1b3ab3145/'
        target="_blank"
        rel="noopener noreferrer">
          <FaLinkedin />
        </a>
      </li>
      <li>
        <a href="https://github.com/future3004"
           target="_blank"
            rel="noopener noreferrer">
          <FaGithub />
        </a>
      </li>
    </StyledSocialIcons>
  );
};

export default SocialIcons;

