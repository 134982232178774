import React from 'react';
import { Wrapper, H3, Content } from './styles/ContactMe.styled';
import { Flex } from './styles/Flex.styled';
import { FaTwitter, FaLinkedin } from 'react-icons/fa'
import { AiOutlineMail } from 'react-icons/ai'


const ContactMe = () => {
    return (
        <Wrapper >
            <h2>Contact me</h2>
            
            <Flex>
                <a >
                        <AiOutlineMail />
                </a>
                
                <H3>richardmel@outlook.com</H3>
            </Flex>
            <Flex>
                <a href='https://www.linkedin.com/in/richard-kalibbala-1b3ab3145/'
                    target="_blank"
                    rel="noopener noreferrer">
                        <FaLinkedin />
                </a>
                
                <H3>Linkedin</H3>
            </Flex>
            <Flex>
                <a href="https://twitter.com/richard_km5"
                    target="_blank"
                    rel="noopener noreferrer">
                        <FaTwitter />
                </a>
                <H3>Twitter</H3>
            </Flex>
        </Wrapper>
    );
};

export default ContactMe;