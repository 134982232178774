import styled from 'styled-components';

export const Wrapper = styled.div`
    //background-color: var(--light-three);
    width: 1000px;
    max-width: 100%;
    padding: 0 20px;
    margin: 0 auto;
    height: 500px;


    a {
    border: 1px solid #000;
    border-radius: 50%;
    //color: #fff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    //margin-right: 10px;
    height: 40px;
    width: 40px;
    text-decoration: none;
  }

  h2 {
    //text-decoration: underline;
    //text-decoration-color: var(--lightBlue);
    //border-bottom: 1px #784cfb solid;
    padding-bottom: 5px;
    display: inline-block;
    font-weight: 300;
    position: relative;
    //#784cfb;
  }

  h2:before {
    content: "";
    position: absolute;
    width: 50%;
    height: 1px;
    bottom: 0;
    left: 25%;
    border-bottom: 1px solid #784cfb;
  }

`
export const Content = styled.div`
    //width: 1000px;
    max-width: 100%;
    padding: 0 20px;
    margin: 0 auto;

`

export const H3 = styled.h3`
    margin-left: 10px;
`