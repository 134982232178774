const content = [
    {
      id: 1,
      title: 'MovieDB App',
      body: 'I worked with: API usage, react hooks, reactJS(props, state, router), prop-types, styled components, storage. This is one of my favorite projects because i use it to find movies to watch. ',
      gitLink: 'https://github.com/future3004/movieDB/',
      appLink: 'https://unruffled-tesla-3b27f5.netlify.app/'
    },
    {
      id: 2,
      title: 'Story Book',
      body: "This is a backend app using Node.js/Express/MongoDB with Google OAuth for authentication, express-handlebars for views, mongoose for db access, passport",
      gitLink: 'https://github.com/future3004/story/',
      appLink: 'https://www.rkm.life/'
    },
    {
      id: 3,
      title: 'Stay Fit App',
      body: "Front and backend app with the MERN stack",
      gitLink: 'https://github.com/future3004/fit-app/',
      appLink: 'https://rkmstorytales.herokuapp.com/'
    },
    {
      id: 4,
      title: 'YouTube API',
      body: "YouTube Api, fetch, promises, react-router-dom",
      gitLink: 'https://github.com/future3004/rkmTubeApp/',
      appLink: 'https://lucid-brahmagupta-a09e8f.netlify.app/'
    },
    {
      id: 5,
      title: 'Firebase Authentication',
      body: "User authentication using Google's Firebase.Implemented the login and logout flow with google firebase.",
      gitLink: 'https://github.com/future3004/rkmFirebaseApp/',
      appLink: 'https://vigilant-liskov-8c5a6b.netlify.app/'
    },
    {
      id: 6,
      title: 'Rwenzo website',
      body: "Built with ReactJs",
      gitLink: 'https://github.com/future3004/rwenzoProject/',
      appLink: 'https://www.rwenzo.com/'
    },
  ]
  
  export default content;