import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 100%;
    //width: 1000px;
    //max-width: 100%;
    //padding: 0 20px;
    //margin: 0 auto;
    background-color: var(--light-three);
    overflow: hidden;
    position: relative;
`;

export const Content = styled.div`
    /* display: flex;
    align-items: center; */
  /* width: 1000px;
  max-width: 100%;
  padding: 0 20px;
  margin: 0 auto;  */
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  top: 0;
  left: 0;
`;

export const GridContent = styled.div`
  grid-template-columns: 2.5fr 3.5fr;
  min-height: calc(100vh - 6rem);
  //padding-bottom: 2.5rem;
  text-align: left;

    max-width: 100%;
    padding: 0 20px;
    margin: 0 auto;
    //height: 500px;
    @media screen and (max-width: ${({ theme }) => theme.mobile}) {
        margin-top: 1rem;
        min-height: calc(100vh - 4.5rem);
        padding-bottom: 1.5rem;
  }
`
export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 5;
  max-width: 92rem;
  padding: 0 4rem;
  margin: 0 auto;

  @media screen and (max-width: ${({ theme }) => theme.mobile}) {
    grid-template-columns: 1fr !important;
    min-height: calc(100vh - 4.5rem);
    padding-bottom: 1.5rem;
    min-height: 80vh;
  }
`

export const ColumnOne = styled.div`
    margin-right: 1.5rem;

    h1, h2 {
        font-size: 3.8rem;
        line-height: 0.8;
        color: var(--dark-one);
    }

    p {
        font-size: 1.25rem;
        color: var(--dark-one);
        line-height: 1.6;    
    }

    @media screen and (max-width: ${({ theme }) => theme.mobile}) {
        margin-right: 0;
        margin-bottom: 1rem;
        max-width: 550px;
        margin: 0 auto;
  }
`
export const ColumnTwo = styled.div`
    margin-left: 2.5rem;
    @media screen and (max-width: ${({ theme }) => theme.mobile}) {
        margin-right: 0;
        margin-top: 1rem;
        max-width: 400px;
        margin: 0 auto;
    }
`

export const Image = styled.img`
/*     border-radius: 100% 50%;
    height: 55%;
    width: 25%; 
    margin: 20px 0 0 30px; */
    width: initial;
    height: 55px;
    opacity: 0.13;
    position: absolute;

`;

export const Square = styled.img`
    width: initial;
    opacity: 0.13;
    position: absolute;
    right: 68%;
    top: 71%;

    @media screen and (max-width: ${({ theme }) => theme.mobile}) {
        top: initial;
        bottom: 5%;
        left: 13%;
  }
`

export const Wave1 = styled.img`
    height: initial;
    width: 88px;
    bottom: 75%;
    left: 20%;
    //width: initial;
    //height: 55px;
    opacity: 0.13;
    position: absolute;

    @media screen and (max-width: ${({ theme }) => theme.mobile}) {
        bottom: 87%;
        left: 75%;
    }

`

export const Wave2 = styled.img`
    //height: initial;
    //width: 88px;
    right: 55%;
    bottom: 8%;
    width: initial;
    height: 55px;
    opacity: 0.13;
    position: absolute;

    @media screen and (max-width: ${({ theme }) => theme.mobile}) {
        display: none;
    }

`

export const Xshape = styled.img`
    width: initial;
    opacity: 0.13;
    position: absolute;
    height: 38px;
    opacity: 0.13;

    @media screen and (max-width: ${({ theme }) => theme.mobile}) {
        left: 90%;
        top: 54%;
  }


`
export const Circle = styled.img`
    width: initial;
    height: 55px;
    opacity: 0.13;
    position: absolute;
    left: 38%;
    bottom: 63%;

    @media screen and (max-width: ${({ theme }) => theme.mobile}) {
        left: 8%;
        bottom: 40%;
  }

`
export const HalfCircle1 = styled.img`
    width: initial;
    height: 55px;
    opacity: 0.13;
    position: absolute;
    left: 50%;
    bottom: 82%;

    @media screen and (max-width: ${({ theme }) => theme.mobile}) {
        bottom: 55%;
        left: 23%;
  }

`

export const Triangle = styled.img`
    width: initial;
    height: 55px;
    opacity: 0.13;
    position: absolute;
    right: 7%;
    bottom: 75%;

    @media screen and (max-width: ${({ theme }) => theme.mobile}) {
        right: 14%;
        bottom: 63%;
  }

`

export const Letters = styled.img`
  position: absolute;
  width: 13.5%;
  top: -5px;
  left: 15px;
  opacity: 0.06;

`

export const HalfCircle2 = styled.img`
    width: initial;
    height: 55px;
    opacity: 0.13;
    position: absolute;
    left: 5%;
    top: 67%;

`

export const Points = styled.img`
    width: 420px;
    bottom: -75px;
    left: -150px;
    opacity: 0.3;
    position: absolute;
`

export const Points2 = styled.img`
    width: 70%;
    top: 65%;
    bottom: -75px;
    left: 71%;
    opacity: 0.3;
    position: absolute;

    @media screen and (max-width: ${({ theme }) => theme.mobile}) {
        width: 280px;
        bottom: -10px;
        left: 60%;
  }
`
export const Button = styled.button`
  display: inline-block;
  padding: 0.85rem 2rem;
  background-color: teal;//#ff0066;
  color: #fff;
  border-radius: 2rem;
  font-size: 1.05rem;
  text-transform: uppercase;
  font-weight: 500;
  transition: 0.3s;
  cursor: pointer;

  &:hover {
    //background-color: #6b44e0;
  }
`

export const PersonImage = styled.img `
    max-width: 750px;
    position: relative;
    z-index: 2;

    @media screen and (max-width: ${({ theme }) => theme.mobile}) {
        margin-top: 2rem;
        max-width: 250px;
  }
`