import styled from 'styled-components'

export const ProjectCard = styled.div`
  //display: flex;
  align-items: center;
  background-color: #363d4a;
  border-radius: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  margin: 40px 20px;
  padding: 60px;
  width: 50%;
  height: 450px;
  position: relative;
  //flex-direction: ${({ layout }) => layout || 'row'};


  @media (max-width: ${({ theme }) => theme.mobile}) {
    //flex-direction: column;
    width: 100%;
    height: 450px;
  }

`

export const Content = styled.div`
  h2 {
    color: #fff;
    font-size: xx-large;
    //opacity: 0.6;
    //line-height: 1.5;
  }
  p {
    color: #fff;
    //opacity: 0.6;
    //line-height: 1.5;
  }
`

export const BtnContainer = styled.div`
    position: absolute;
    bottom: 30px;
    left: 13px;
`


export const Button = styled.button`
  margin-left: 6px;
  border-radius: 50px;
  border: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  font-size: 16px;
  font-weight: 300;
  padding: 15px 40px;
  background-color: ${({ bg }) => bg || '#fff'};
  color: ${({ color }) => color || '#333'};

  &:hover {
    opacity: 0.9;
    transform: scale(0.98);
  }

`
export const CardProjectFlex = styled.div`
  display: flex;
  align-items: center;

  & > div,
  & > ul {
    flex: 1;
  }

  @media screen and (max-width: ${({ theme }) => theme.mobile}) {
    //flex-direction: column;
    //text-align: center;
  }
`

