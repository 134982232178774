import React from 'react';
import { saveAs } from 'file-saver';
import { 
  Wrapper, 
  Content, 
  PersonImage,
  Square,
  Wave1,
  Wave2,
  Xshape,
  Points,
  Points2,
  Circle,
  HalfCircle1,
  HalfCircle2,
  Triangle,
  Letters,
  GridContent,
  ColumnOne,
  ColumnTwo,
  Button,
  GridContainer,
} from './styles/Home.styled';
import Picture from '../images/person.png';
import MyImage from '../images/mel_pic.png';

// shapes
import square from '../images/shapes/square.png'
import circle from '../images/shapes/circle.png'
import halfCircle from '../images/shapes/half-circle.png'
import xshape from '../images/shapes/x.png'
import wave from '../images/shapes/wave.png'
import triangle from '../images/shapes/triangle.png'
import letters from '../images/shapes/letters.png'
import points1 from '../images/shapes/points1.png'
import points2 from '../images/shapes/points2.png'


const Home = () => {
  const getResume = () => {
    saveAs(
      'https://rkmsite.s3.us-east-2.amazonaws.com/assets/resume.pdf', 
      'richard_kalibbala_resume.pdf'
    );
  }
  return (
    <Wrapper>
      <Content>
        <Square src={square} alt='' />
        <Circle src={circle} alt='' />
        <HalfCircle1 src={halfCircle} alt='' />
        <HalfCircle2 src={halfCircle} alt='' />
        <Xshape src={xshape} alt='' />
        <Wave1 src={wave} alt='' />
        <Wave2 src={wave} alt='' />
        <Triangle src={triangle} alt='' />
        {/* <Letters src={letters} alt='' /> */}
        <Points src={points1} alt='' />
      </Content>

      <GridContent>
        <GridContainer>
          <ColumnOne>
            <h1>Richard Kalibbala</h1>
            <h2>Web Developer</h2>
            <p>
              Hello, I'm Richard Kalibbala. <br />
              I am a Frontend and Backend Web Developer.
            </p>
            <Button onClick={getResume}>Download Resume</Button>
          </ColumnOne>

          <ColumnTwo>
            <Points2 src={points2} alt='points' />
            <PersonImage src={MyImage} alt='logo' />
          </ColumnTwo>
        </GridContainer>
      </GridContent>
       
    </Wrapper>
  );
};

export default Home;