import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
 @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');
:root {
    --maxWidth: 1280px;
    --white: #fff;
    --lightGrey: #eee;
    --medGrey: #353535;
    --lightBlue: #3366ff;
    --darkGrey: #1c1c1c;
    --dark-one: #333;
    --dark-two: #7a7a7a;
    --main-color: #784cfb;
    --light-one: #fff;
    --light-two: #f9fafb;
    --light-three: #f6f7fb;
    --fontSuperBig: 2.5rem;
    --fontBig: 1.5rem;
    --fontMed: 1.2rem;
    --fontSmall: 1rem;
  }

  * {
    box-sizing: border-box;
    font-family: 'Abel', sans-serif;
  } 

  body {
    background: ${({ theme }) => theme.colors.body};
    //background: #f6f7fb;
    color: hsl(192, 100%, 9%);
    font-family: 'Poppins', sans-serif;
    font-size: 1.15em;
    margin: 0;
  }

  h1 {
    font-size: 2rem;
    font-weight: 600;
    color: var(--white);
  }

  h3 {
    font-size: 1.1rem;
    font-weight: 600;
  }

/*   p {
    //font-size: 1rem; 
    opacity: 0.6;
    line-height: 1.5;
  } */

  ul {
    list-style: none;
  }
`;